import React, { useState } from "react";
import { Card, List, Spin, Button } from "antd";
import { Section, InlineButton, LazyImage } from "../components";
import { TranslationContext } from "../i18n";
import firebase from "@firebase/app";
import Markdown from "markdown-to-jsx";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useRef } from "react";

const ConditionalDisplay = styled.div`
  display: flex;
`;

const Cond = styled.div`
  flex: 0 1 0%;
  flex-basis: 1;
  transition: flex 0.3s ease, width 0.3s ease, opacity 0.3s ease;
`;

const Inline = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Fullitem = ({ item, onClose }) => (
  <Inline>
    <InlineButton onClick={onClose}>
      <LeftOutlined style={{ fontSize: "1.5em" }} />
    </InlineButton>
    <div style={{ marginLeft: 10 }}>
      <LazyImage src={item.full.image} />
    </div>
    <div style={{ marginLeft: 35 }}>
      <Markdown>{item.full.markdown}</Markdown>
    </div>
  </Inline>
);

const RightIcon = styled.div`
  float: right;
`;

const ListItem = ({ item, onClick, idx }) => {
  const title = item.composer.replace("[b]", "");
  if (!item.desc) debugger;
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<LazyImage width={50} height={50} src={item.miniPicture} />}
        title={title}
        description={
          <>
            <RightIcon>
              {item.full && (
                <InlineButton onClick={onClick}>
                  <RightOutlined style={{ fontSize: "1.5em" }} />
                </InlineButton>
              )}
            </RightIcon>
            <div>
              {item.desc.split("[b]").map((i, idx2) => (
                <p key={`disco.${idx}.${idx2}`}>{i}</p>
              ))}
            </div>
          </>
        }
      />
    </List.Item>
  );
};

export const Discography = ({ secondary, foregroundRef }) => {
  const [displayFull, setDisplayFull] = useState(null);
  const ref = useRef();
  const scrollToRef = (ref) => {
    debugger;
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };
  const displayStyle = {
    flexGrow: 1,
    flexBasis: "100%",
    width: "100%",
    opacity: 1,
  };
  const hideStyle = {
    flexGrow: 0,
    flexBasis: "0%",
    width: "0%",
    opacity: 0,
  };
  return (
    <Section
      ref={ref}
      id="discography"
      title="discographyTitle"
      secondary={secondary}
    >
      <TranslationContext.Consumer>
        {(translations) => (
          <ConditionalDisplay>
            <Cond style={!!displayFull ? hideStyle : displayStyle}>
              {
                <List
                  pagination={{
                    pageSize: 7,
                    onChange: () => setTimeout(() => scrollToRef(ref), 100),
                  }}
                  itemLayout="horizontal"
                  dataSource={translations.discography}
                  renderItem={(item, i) => (
                    <ListItem
                      key={`disco.${i}`}
                      onClick={() => {
                        scrollToRef(ref);
                        setDisplayFull(item);
                      }}
                      idx={i}
                      item={item}
                    />
                  )}
                />
              }
            </Cond>
            <Cond style={!!displayFull ? displayStyle : hideStyle}>
              {displayFull && (
                <Fullitem
                  item={displayFull}
                  onClose={() => setDisplayFull(null)}
                />
              )}
            </Cond>
          </ConditionalDisplay>
        )}
      </TranslationContext.Consumer>
    </Section>
  );
};
