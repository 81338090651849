import React, { useRef } from "react";
import { TranslationContext } from "../i18n";
import { Section } from "../components";
import { List } from "antd";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Bold = styled.span`
  font-weight: bold;
`;

const ListItem = ({ item }) => {
  const arr = item.title.match(/\*.*?\*/);
  const title = arr[0];
  const content = item.title.replace(title, "").trim();
  return (
    <List.Item>
      <List.Item.Meta
        title={title.replace(/\*/g, "")}
        description={item.year}
      />
      {content}
    </List.Item>
  );
};

export const Compositions = ({ secondary }) => {
  const ref = useRef();
  const scrollToRef = (ref) => {
    debugger;
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };
  return (
    <Section
      id="compositions"
      title="compositionsTitle"
      secondary={secondary}
      ref={ref}
    >
      <TranslationContext.Consumer>
        {(translations) => (
          <List
            pagination={{ pageSize: 7, onChange: () => scrollToRef(ref) }}
            itemLayout="horizontal"
            dataSource={translations.compositions}
            renderItem={(item) => <ListItem key={item.title} item={item} />}
          />
        )}
      </TranslationContext.Consumer>
    </Section>
  );
};
