import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import firebase from "@firebase/app";

const storage = firebase.storage();

export const LazyImage = ({ src, width = 200, height = 200 }) => {
  const [url, setURL] = useState(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    storage.ref(src).getDownloadURL().then(setURL);
  }, [src]);

  return (
    <>
      {!loaded && (
        <ContentLoader
          speed={2}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
        </ContentLoader>
      )}
      {url && (
        <img
          style={{ display: loaded ? "block" : "none" }}
          src={url}
          onLoad={() => setLoaded(true)}
        />
      )}
    </>
  );
};
