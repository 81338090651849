export const firebaseConfig = {
  apiKey: "AIzaSyAzHn-bzHusvUSx8m0yzL16TxUC9hIF52Y",
  authDomain: "marcelcominottobe-821b2.firebaseapp.com",
  databaseURL: "https://marcelcominottobe-821b2.firebaseio.com",
  projectId: "marcelcominottobe-821b2",
  storageBucket: "marcelcominottobe-821b2.appspot.com",
  messagingSenderId: "607002953143",
  appId: "1:607002953143:web:3c699fb8ce1ab2dc685432",
  measurementId: "G-DKJZ959H9D"
};
