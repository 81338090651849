import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

const Container = styled.div`
  width: 300px;
  height: 300px;
  border: 0px;
  border-radius: 150px;
  background-color: ${(props) => props.theme.DarkGreen};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = ({}) => (
  <Container>
    <Lottie
      options={{
        animationData: require("./Loader.json"),
      }}
      width={300}
      height={300}
    />
  </Container>
);
