import React from "react";
import { Card, Form, Input, Button, message } from "antd";
import { Section } from "../components";
import { TranslationContext } from "../i18n";
import styled from "styled-components";
import firebase from "@firebase/app";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { MailOutlined } from "@ant-design/icons";

export const Contact = withGoogleReCaptcha(
  ({ secondary, googleReCaptchaProps }) => {
    const [form] = Form.useForm();
    return (
    <Section id="contact" title="contactTitle" secondary={secondary}>
      <a style={{display: "flex", alignItems: "center", gap: 5, fontSize: 16}}  href="mailto:marcel.cominotto@crlg.be"><MailOutlined /> marcel.cominotto@crlg.be</a>
    </Section>
  )
});
