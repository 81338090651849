import firebase from "@firebase/app";
import "@firebase/database";
import "@firebase/storage";
import { firebaseConfig } from "../config";

firebase.initializeApp(firebaseConfig);

let $translations = null;

let $lang = "fr";

const allTranslations = async () => {
  if (!$translations) {
    $translations = await firebase
      .database()
      .ref("/i18n")
      .once("value")
      .then((v) => v.val());
  }
  return $translations;
};

export const setLanguage = (lg) => ($lang = lg);

export const translations = async (lg = $lang) => {
  const ts = await allTranslations();
  const trans = Object.keys(ts).reduce(
    (acc, k) => ({ ...acc, [k]: ts[k][lg] }),
    {}
  );
  return trans;
};
