import React from "react";

import { Drawer } from "antd";
import { SideAnchors } from "./SideAnchors";

export const AnchorsDrawer = ({ visible, onClose }) => (
  <Drawer
    title="Menu"
    placement="right"
    closable={false}
    onClose={onClose}
    visible={visible}
  >
    <SideAnchors />
  </Drawer>
);
