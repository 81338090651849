import React from "react";
import styled from "styled-components";

export const InlineButton = styled.button`
  border: none;
  outline: none;
  align-self: start;
  z-index: 10;
  pointer: cursor;
`;
