import React, { useState, useRef, useEffect } from "react";
import { Layout, Spin, Affix } from "antd";
import "./App.css";
import { TranslationContext, translations as loadTranslations } from "./i18n";
import {
  Biography,
  Home,
  Contact,
  Compositions,
  Partitions,
  Discography,
  Samples,
} from "./sections";
import { Header } from "./components/Header";
import {
  SideAnchors,
  AnchorsDrawer,
  Parallax,
  ParallaxBackground,
  ParallaxForeground,
  Loader,
} from "./components";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import styled, { ThemeProvider } from "styled-components";
import {} from "./sections/Discography";
import { PC_MIN_WIDTH, SMARTPHONE_MAX_WIDTH, Theme } from "./config";
import MediaQuery from "react-responsive";
import Icon, { YoutubeOutlined } from "@ant-design/icons";
import { Critics } from "./sections/Critics";

const StyledLayout = styled(Layout)`
  background-color: transparent;
`;

const InnerLayout = styled(StyledLayout)`
  margin-top: 64px;
  background-color: transparent;
`;

const StyledSider = styled(Layout.Sider)`
  margin-top: 20vh;
  background-color: transparent;
`;

const Background = styled(ParallaxBackground)`
  background-image: url("/Partition.jpg");
  background-size: 100%;
  /* Keep the image from overlapping sibling elements. */
  z-index: -1;
`;

const StickyAnchors = styled.div`
  position: sticky;
  top: 30%;
`;

export default () => {
  const [translations, setTranslations] = useState(null);
  const [lang, setLang] = useState("fr");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
    loadTranslations(lang).then(setTranslations);
  }, []);
  const changeLang = (lg) => {
    setLang(lg);

    loadTranslations(lg).then(setTranslations);
  };
  const [containerRef, setContainerRef] = useState(null);
  const [foregroundRef, setForegroundRef] = useState(null);
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ld93-UUAAAAAGzKxNY3qE8g9a8Q-D9C9ExOTXv6">
      <ThemeProvider theme={Theme}>
        <Parallax ref={setContainerRef}>
          <Background />
          <ParallaxForeground>
            <Spin spinning={!translations && loading} indicator={<Loader />}>
              {translations && (
                <>
                  <TranslationContext.Provider value={translations}>
                    <StyledLayout>
                      <Header
                        lang={lang}
                        onChangeLang={changeLang}
                        onDrawerActivate={() => setDrawerVisible(true)}
                      />
                      <InnerLayout>
                        <MediaQuery minDeviceWidth={PC_MIN_WIDTH}>
                          <StyledSider theme="light" />
                        </MediaQuery>
                        <Layout.Content>
                          <div ref={setForegroundRef}>
                            <Home />
                            <Biography secondary />
                            <Compositions />
                            <Partitions secondary />
                            <Discography foregroundRef={foregroundRef} />
                            <Samples secondary />
                            <Critics />
                            <Contact secondary />
                          </div>
                        </Layout.Content>
                        <MediaQuery minDeviceWidth={PC_MIN_WIDTH}>
                          <StyledSider theme="light">
                            <StickyAnchors>
                              <SideAnchors
                                style={{
                                  backgroundColor: "rgba(255,255,255, 0.9)",
                                }}
                                containerRef={containerRef}
                              />
                            </StickyAnchors>
                          </StyledSider>
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={SMARTPHONE_MAX_WIDTH}>
                          <AnchorsDrawer
                            visible={drawerVisible}
                            onClose={() => setDrawerVisible(false)}
                          />
                        </MediaQuery>
                      </InnerLayout>

                      <Layout.Footer style={{ textAlign: "center" }}>
                        <a
                          href="https://www.youtube.com/channel/UCwYGbgAHO0vYK2bqRHS-ssA"
                          target="_blank"
                        >
                          <YoutubeOutlined style={{ fontSize: "2em" }} />
                        </a>
                      </Layout.Footer>
                    </StyledLayout>
                  </TranslationContext.Provider>
                </>
              )}
            </Spin>
          </ParallaxForeground>
        </Parallax>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};
