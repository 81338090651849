import React, { forwardRef } from "react";
import styled from "styled-components";
import { TranslationContext } from "../i18n";
import { Card } from "antd";
import { Theme } from "../config";

const Content = styled.div`
  color: ${(props) => props.theme.RichBlack};
  font-size: 16px;
`;

const StyledSection = styled.section`
  margin-top: 32px;
  background-color: white;
  margin-right: 10px;
`;

const StyledCard = styled(Card)`
  background-color: ${(props) =>
    !props.secondary ? props.theme.bgPrimary : props.theme.bgSecondary};
  scroll-margin: -50px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 36px;
  font-family: cursive;
  color: ${(props) => props.theme.RichBlack};
`;

export const Section = forwardRef(({ id, title, children, secondary }, ref) => {
  return (
    <StyledSection>
      <TranslationContext.Consumer>
        {(translations) => (
          <StyledCard secondary={secondary}>
            <Title ref={ref} id={id}>
              {translations[title]}
            </Title>
            <Content>{children}</Content>
          </StyledCard>
        )}
      </TranslationContext.Consumer>
    </StyledSection>
  );
});
