import React, { useEffect, useState, useRef } from "react";

export function Carousel({ items, renderItem }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const freeze = useRef(false);

  useEffect(() => {
    // This variable prevents race condition
    let current = 0;
    const cycleReviews = () => {
      if (current === items.length - 1) {
        current = 0;
      } else {
        current += 1;
      }
      setActiveSlide(current);
    };
    // intervalId identified so it can be canceled on unmount
    const intervalId = setInterval(() => {
      if (!freeze.current) {
        cycleReviews();
      }
    }, 10000);
    // Removes interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="carousel">
      <ul className="carousel__list">
        {items.map((item, index) => {
          const count = index;
          return (
            <li
              className={`carousel__item
                ${count === activeSlide ? " active" : ""}
                ${count < activeSlide ? " left" : ""}
                ${count > activeSlide ? " right" : ""}
              `}
              key={count}
            >
              {renderItem(item)}
            </li>
          );
        })}
        <li
          className="carousel__indicator"
          style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
        >
          {items.map((i, idx) => (
            <span
              onClick={() => {
                setActiveSlide(idx);
                freeze.current = true;
              }}
              className={`carousel__dot${activeSlide === idx ? " active" : ""}`}
            />
          ))}
        </li>
      </ul>
    </div>
  );
}
