const LightGray = "#f6f5f5";
const DarkGray = "#e3e3e3";
const LightGreen = "rgba(59, 180, 193, .1)";
const LightGreenOpaque = "rgb(59, 180, 193)";
const DarkGreen = "#048998";
const DarkGreenLightened = "#c7eaee";
const RichBlack = "#010203";

export const Theme = {
  RichBlack,
  LightGray,
  DarkGray,
  LightGreen,
  DarkGreen,
  LightGreenOpaque,
  DarkGreenLightened,
  bgPrimary: "white",
  bgSecondary: LightGreen,
  bgBody: "rgb(251, 247, 245)",
};
