import React from "react";
import styled from "styled-components";

export const Parallax = styled.div`
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) => props.theme.bgBody};
`;

export const ParallaxForeground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
`;

export const ParallaxBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateZ(-3px) scale(4);
  height: 2684px;
`;
