import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Section } from "../components";
import { TranslationContext } from "../i18n";
import firebase from "@firebase/app";
import ReadMoreReact from "read-more-react";
import { Carousel } from "../components";
import styled from "styled-components";

const Source = ({ source, sourceLink }) => {
  if (source && !sourceLink) return <span>{source}</span>;
  else
    return (
      <a href={sourceLink} target="_blank">
        {source}
      </a>
    );
};

const ReadMoreBtn = styled(Button)``;

const Critic = ({ critic, readMoreText, readLessText }) => {
  const { title, body, short, source, sourceLink } = critic;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <h3>
        {title} {source && " - "}
        {source && <Source source={source} sourceLink={sourceLink} />}
      </h3>
      <p>
        {expanded ? body : short}
        {body && (
          <ReadMoreBtn type="link" onClick={() => setExpanded(!expanded)}>
            {expanded ? readLessText : readMoreText}
          </ReadMoreBtn>
        )}
      </p>
    </div>
  );
};

export const Critics = ({ secondary }) => {
  return (
    <Section id="critics" title="criticsTitle" secondary={secondary}>
      <TranslationContext.Consumer>
        {(translations) => (
          <div>
            <Carousel
              items={translations.critics}
              renderItem={(critic) => (
                <Critic
                  key={critic.title}
                  critic={critic}
                  readMoreText={translations.readMore}
                  readLessText={translations.readLess}
                />
              )}
            />
          </div>
        )}
      </TranslationContext.Consumer>
    </Section>
  );
};
