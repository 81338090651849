import React from "react";
import { Card } from "antd";
import { TranslationContext } from "../i18n";
import styled from "styled-components";
import { Section } from "../components";

const Content = styled.div``;
export const Biography = ({ secondary }) => {
  return (
    <Section id="biography" title="biographyTitle" secondary={secondary}>
      <TranslationContext.Consumer>
        {(translations) =>
          translations.biographyFull.map((p, i) => <p key={`bio.${i}`}>{p}</p>)
        }
      </TranslationContext.Consumer>
    </Section>
  );
};
