import React from "react";
import { Select, Layout, Affix, Menu, Anchor, Button } from "antd";
import { TranslationContext } from "../i18n";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { SMARTPHONE_MAX_WIDTH, Theme } from "../config";
import { MenuFoldOutlined } from "@ant-design/icons";

const Logo = styled.img`
  width: 120px;
  height: 60px;
  background-size: auto;
  margin: 2px 28px 2px 0;
  float: left;
`;

const lgMap = {
  fr: "Français",
  en: "English",
  es: "Espanol",
};

const StyledHeader = styled(Layout.Header)`
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
  background: ${Theme.DarkGreenLightened};
`;

const StyledMenu = styled(Menu)`
  background-color: transparent;
`;

const ACT_DRAWER = "activateDrawer";
export const Header = ({ lang, onChangeLang, onDrawerActivate }) => {
  const isSmartphone = useMediaQuery({
    query: `(max-width: ${SMARTPHONE_MAX_WIDTH}px)`,
  });
  const subMenuStyle = isSmartphone ? {} : { float: "right" };
  const headerAdditionalStyle = isSmartphone ? { padding: "0 25px" } : {};

  return (
    <StyledHeader style={headerAdditionalStyle}>
      <Logo src={require("./Logo.png")} />
      <StyledMenu
        selectedKeys={[]}
        theme="light"
        mode="horizontal"
        onSelect={(e) =>
          e.key === ACT_DRAWER ? onDrawerActivate() : onChangeLang(e.key)
        }
      >
        <SubMenu style={subMenuStyle} title={lgMap[lang]}>
          {Object.keys(lgMap).map((l) => (
            <Menu.Item key={l}>{lgMap[l]}</Menu.Item>
          ))}
        </SubMenu>
        {isSmartphone && (
          <Menu.Item style={{ float: "right" }} key={ACT_DRAWER}>
            <MenuFoldOutlined />
          </Menu.Item>
        )}
      </StyledMenu>
    </StyledHeader>
  );
};
