import React, { useState } from "react";
import { Card, List, Spin } from "antd";
import { Section } from "../components";
import { TranslationContext } from "../i18n";
import firebase from "@firebase/app";
import { FilePdfOutlined, FileZipOutlined } from "@ant-design/icons";

const storage = firebase.storage();

const DownloadLink = ({ item }) => {
  const [url, setURL] = useState(null);
  storage.ref(`partitions/${item.file}`).getDownloadURL().then(setURL);
  const Icon = () =>
    item.file.match(/\.pdf$/) ? (
      <FilePdfOutlined style={{ marginRight: 10 }} />
    ) : (
      <FileZipOutlined style={{ marginRight: 10 }} />
    );
  return url ? (
    <a href={url}>
      <Icon />
      {item.file}
    </a>
  ) : (
    <Spin />
  );
};

export const Partitions = ({ secondary }) => {
  return (
    <Section id="partitions" title="partitionsTitle" secondary={secondary}>
      <TranslationContext.Consumer>
        {(translations) => (
          <List
            itemLayout="horizontal"
            dataSource={translations.partitions}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.title} />
                <DownloadLink item={item} />
              </List.Item>
            )}
          />
        )}
      </TranslationContext.Consumer>
    </Section>
  );
};
