import React from "react";
import { Card } from "antd";
import { TranslationContext } from "../i18n";
import styled from "styled-components";
import { Section } from "../components";
import { useMediaQuery } from "react-responsive";
import { SMARTPHONE_MAX_WIDTH } from "../config";

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LeftCol = styled.div`
  flex: 1 1 auto;
`;
const RightCol = styled.div`
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: -24px;
  margin-top: -64px;
  display: flex;
`;

const StyledParagraph = styled.p`
  display: flex;
`;
export const Home = ({ secondary }) => {
  const isSmartphone = useMediaQuery({
    query: `(max-width: ${SMARTPHONE_MAX_WIDTH}px)`,
  });
  return (
    <Section id="home" title="indexTitle" secondary={secondary}>
      <Content>
        <LeftCol>
          <TranslationContext.Consumer>
            {(translations) =>
              translations.home.map((p, i) => (
                <StyledParagraph key={`home.${i}`}>{p}</StyledParagraph>
              ))
            }
          </TranslationContext.Consumer>
        </LeftCol>
        <RightCol>
          <img
            src={require("./assets/marcel.png")}
            width={isSmartphone ? "350px" : "500px"}
            style={{ alignSelf: "flex-start" }}
          />
        </RightCol>
      </Content>
    </Section>
  );
};
