import React from "react";
import { Anchor, Layout, Affix } from "antd";
import { TranslationContext } from "../i18n";
import styled from "styled-components";

const { Link } = Anchor;
const { Sider } = Layout;

export const SideAnchors = ({ containerRef, style }) => {
  return (
    <TranslationContext.Consumer>
      {(translations) => (
        <Anchor
          {...(containerRef ? { getContainer: () => containerRef } : {})}
          style={style}
          offsetTop={64}
          bounds={100}
        >
          <Link href="#home" title={translations.indexTitle} />
          <Link href="#biography" title={translations.biographyTitle} />
          <Link href="#compositions" title={translations.compositionsTitle} />
          <Link href="#partitions" title={translations.partitionsTitle} />
          <Link href="#discography" title={translations.discographyTitle} />
          <Link href="#samples" title={translations.samplesTitle} />
          <Link href="#critics" title={translations.criticsMenuItem} />
          <Link href="#contact" title={translations.contactTitle} />
        </Anchor>
      )}
    </TranslationContext.Consumer>
  );
};
