import React, { useState } from "react";
import { Card, List, Spin } from "antd";
import { Section } from "../components";
import { TranslationContext } from "../i18n";
import firebase from "@firebase/app";

const storage = firebase.storage();

const buildURL = async (item) => storage.ref(`music/${item}`).getDownloadURL();

const AudioSample = ({ item }) => {
  const [url, setURL] = useState(null);
  storage.ref(`music/${item.sample}`).getDownloadURL().then(setURL);
  return url ? (
    <audio src={url} controls preload="none" controlsList="nodownload" />
  ) : (
    <Spin />
  );
};

export const Samples = ({ secondary }) => {
  return (
    <Section id="samples" title="samplesTitle" secondary={secondary}>
      <TranslationContext.Consumer>
        {(translations) => (
          <List
            itemLayout="horizontal"
            dataSource={translations.samples}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.title} description={item.author} />
                <AudioSample item={item} />
              </List.Item>
            )}
          />
        )}
      </TranslationContext.Consumer>
    </Section>
  );
};
